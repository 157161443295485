import React, { useEffect } from 'react';
import CustomerFeesForm from '../Fees/CustomerFeesForm';
import CurrenciesFeesForm from './CurrenciesFeesForm';
import Loader from '../../../../components/Loader';
import { ICustomer, useCustomerFeesList, useCustomerFeeCreate, useCustomerFeeUpdate } from '../../../../api/customers';
import { useCurrenciesList } from '../../../../api/currency';

interface IProps {
  customer: ICustomer;
}

export default function FeesForm({ customer }: IProps) {
  const [{ items: fees }, loading, load] = useCustomerFeesList();
  const [{ items: currencies }, loadingCurrencies, loadCurrencies] = useCurrenciesList();
  const [create, creating] = useCustomerFeeCreate((res: any) => {
    if (res.success) {
      // setFeesForms({ ...feesForms, [res.data.currency]: res.data });
      load(customer.id);
    }
  });
  const [update, updating] = useCustomerFeeUpdate((res: any) => {
    if (res.success) {
      // setFeesForms({ ...feesForms, [res.data.currency]: res.data });
      load(customer.id);
    }
  });

  useEffect(() => {
    if (customer.id) {
      load(customer.id);
      loadCurrencies();
    }
  }, [customer.id])

  return (
    <Loader loading={loading || loadingCurrencies} width={500}>
      <CustomerFeesForm customer={customer} fees={fees} restHandlers={{create, creating, update, updating, load}} />
      <br />
      <CurrenciesFeesForm  customer={customer} currencies={currencies} fees={fees} restHandlers={{create, creating, update, updating, load}} />
    </Loader>
  );
}
