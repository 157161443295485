import React from 'react';
import TableFilter from '../../components/Filter';
import { Input } from '../../components/Form';

export interface IApiKeysFilter {
  queryData?: any;
}

interface IProps {
  filter: IApiKeysFilter;
  onChange: any;
}

export default function ApiKeysFilter({ filter, onChange }: IProps) {
  return (
    <TableFilter filter={filter} onApply={onChange}>
      <Input
        name="queryData"
        label="Customer/Merchant/SID"
        margin="none"
      />
    </TableFilter>
  );
}
