import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { ICustomer } from '../../../api/customers';
import CustomerFeesForm from './Fees/FeesForm';
import CustomerSettingsView from './Fees/SettingsView';

interface IProps {
  customer: ICustomer;
  onSave: any;
}

export default function FeesTab({ customer, onSave }: IProps) {
  return (
    customer.authorities?.includes('ROLE_MERCHANT')
      ? <CustomerFeesForm customer={customer} />
      : <CustomerSettingsView customer={customer} />
  );
}
