import React, { useEffect, useMemo, useRef, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import clsx from 'clsx';
import { Form } from 'react-final-form';
import { OnChange } from "react-final-form-listeners";

import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import Descriptor from '../../../../components/Descriptor';
import useFormStyles from '../../../../hooks/useFormStyles';
import { Checkbox, CurrencySelect, Input, Select } from '../../../../components/Form';
import { composeValidators, isEvmAddress, isURL, minValue, mustBeNumber, required } from '../../../../validators';
import { ICustomer, KycFlowType } from '../../../../api/customers';
import { OrderType } from '../../../../api/order';
import { CurrencyType } from '../../../../api/currency';
import ResendPostbackKycForm from "./ResendKycNotificationForm";
import { usePaymentProvidersList } from "../../../../api/paymentProviders";
import { usePaymentGroupList } from "../../../../api/paymentGroup";
import { useListPaymentMethod } from "../../../../api/paymentMethod";
import ApmSelect from "../../../../components/Form/ApmSelect";
import CustomerBusinessForm from './CustomerBusinessForm';

interface IProps {
  customer: ICustomer;
  onSave: any;
}

const useStyles = makeStyles((theme) => ({
  publicKeyInput: {
    transition: 'background-color .5s linear',
    '&.copied': {
      backgroundColor: 'green'
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  publicKeyButton: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '20%',
    [theme.breakpoints.down('xs')]: {
      width: '50%',
      marginLeft: '0px',
    },
  },
  kycNotification: {
    marginTop: theme.spacing(2)
  },

  kycReset: {
    marginTop: theme.spacing(2)
  },
  descriptorWrapper: {
    maxWidth: '520px'
  },
  rowPostback: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      gap: '10px',
      flexDirection: 'column',
    },
  },
  checkbox: {
    alignSelf: 'center',
    [theme.breakpoints.down(700)]: {
      alignSelf: 'flex-start',
    },
  },
  descriptorMobile: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    },
  },
  url: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }
}));

export default function CustomerSettingsForm({ customer, onSave }: IProps) {
  const formClasses = useFormStyles();
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const publicKeyRef = useRef(null);
  const [providersList, loadingProviders, loadProviders] = usePaymentProvidersList();
  const [groupList, loadingGroups, loadGroups] = usePaymentGroupList();
  const [methodList, loadingMethods, loadMethods] = useListPaymentMethod();

  const copyToClipboard = () => {
    if (publicKeyRef.current) {
      const textarea = document.createElement('textarea');
      textarea.style.position='absolute';
      textarea.style.left='-100000px';

      document.body.appendChild(textarea);
      //@ts-ignore
      const text = preparePemKey(publicKeyRef.current.value);
      textarea.value = text; // chrome uses this
      textarea.textContent = text; // FF uses this
      //@ts-ignore
      textarea.select();
      document.execCommand('copy');
      setCopied(true);
    }
  };
  const downloadPublicKey = () => {
    if (publicKeyRef.current) {
      const element = document.createElement('a');
      //@ts-ignore
      const text = preparePemKey(publicKeyRef.current.value);
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
      element.setAttribute('download', 'key.pem');

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }
  const preparePemKey = (publicKey: string) => {
    return (
      '-----BEGIN PUBLIC KEY-----\n'+
      publicKey.replace(/(.{64})/g,"$1\n")+"\n"+
      '-----END PUBLIC KEY-----'
    );
  }

  useEffect(() => {
    loadGroups();
    loadMethods();
    loadProviders({});
  }, [])

  useEffect(() => {
    if(copied){
      setTimeout(() => setCopied(false), 2000);
    }
  }, [copied])

  const providers = useMemo(() => {
    return providersList.items.map((i: any) => ({ label: i.name, value: i.id })).filter((v: any) => !!v);
  }, [providersList]);

  const groups = useMemo(() => {
    return groupList.items.map((i: any) => ({ label: i.name, value: i.id })).filter((v: any) => !!v);
  }, [groupList]);

  const methods = useMemo(() => {
    return methodList.items.map((i: any) => ({ label: i.name, value: i.id })).filter((v: any) => !!v);
  }, [methodList]);

  const transactionTypePreferences = [
    { value: 'instant_only', label: 'Only Instant banks' },
    { value: 'instant_preferred', label: 'Instant preferable (All SEPA + Instant)' },
    { value: 'standard', label: 'Only SEPA Credit (All SEPA + Instant)' }
  ];

  return (
    <Form
      onSubmit={(values) => {
        const data = { ...values };
        if (customer.authorities?.includes('ROLE_MERCHANT')) {
          if (!data.successUrl) {
            data.successUrl = '';
          }
          if (!data.failUrl) {
            data.failUrl = '';
          }

          if (customer.settings.postback && !data.postback){
            data.postback = { ...customer.settings.postback};
          }
          if(data.postback){
            data.postback.url = data.postback.url || '';
            data.postback.kycUrl = data.postback.kycUrl || '';
            data.postback.kycNotification = data.postback.kycNotification || false;
          }

          if (!data.settlementCryptoCurrency) {
            data.settlementCryptoCurrency = null;
          }

          if (customer.settings.ccw && !data.ccw){
            data.ccw = { ...customer.settings.ccw};
          }
          if(data.ccw){
            data.ccw.factory = data.ccw.factory || '0x0';
            data.ccw.vault = data.ccw.vault || '0x0';
            data.ccw.enabled = data.ccw.enabled || false;
          }
        }
        onSave(data);
      }}
      initialValues={{
        includedCurrencies: customer.includedCurrencies,
        excludedCurrencies: customer.excludedCurrencies,
        disabledLegalEntityLimitsApm: customer.disabledLegalEntityLimitsApm,
        smsesPerDay: customer.dailySmsLimit,
        obiPaymentScheme: customer?.settings?.obiPaymentScheme ?? 'instant_preferred',
        ...customer.settings, 
      }}
      mutators={{
        setKycNotification([value], state, { changeValue }){
          if(!value) {
            changeValue(state, 'postback.kycNotification', () => false);
          }
        }
      }}
      render={({ handleSubmit, values, invalid, form }) => {
        return (
          <div className={formClasses.root}>
            <div className={formClasses.row}>
              <Typography variant="body1" color="primary" align="center">Settings</Typography>
            </div>
            <Typography variant="body2" color="primary">Postback</Typography>
            <div className={clsx(formClasses.row, classes.rowPostback)}>
              <Descriptor group="customerSettings" title="URL" top={7} className={classes.url}>
                <Input name="postback.url" label="URL" validate={isURL}/>
              </Descriptor>
              <Descriptor group="customerSettings" title="Public Key" top={7} className={classes.descriptorMobile}>
                <Input inputRef={publicKeyRef} InputProps={{readOnly: true}} className={clsx(classes.publicKeyInput, {copied})} name="postback.publicKey" label="Public Key" fullWidth/>
                <Button variant="contained" size="large" className={classes.publicKeyButton} onClick={copyToClipboard}>Copy</Button>
                <Button variant="contained" size="large" className={classes.publicKeyButton} onClick={downloadPublicKey}>Download</Button>
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Success URL" top={7}>
                <Input name="successUrl" label="Success URL" validate={isURL} />
              </Descriptor>
              <Descriptor group="customerSettings" title="Fail URL" top={7}>
                <Input name="failUrl" label="Fail URL" validate={isURL} />
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="KYC notification URL" top={7}>
                <Input
                  name="postback.kycUrl"
                  label="KYC notification URL"
                  validate={isURL}
                />
              </Descriptor>
              <OnChange name="postback.kycUrl">
                {(v: string) => form.mutators.setKycNotification(v)}
              </OnChange>
              <Descriptor group="customerSettings" title="Enable KYC notification" top={7}>
                <Checkbox
                  name="postback.kycNotification"
                  label="Enable KYC notification"
                  className={classes.kycNotification}
                  disabled={!values.postback?.kycUrl}
                  checked={values.postback?.kycNotification}
                />
              </Descriptor>
              <Descriptor group="customerSettings" title="Enable KYC notification" top={7}>
                <ResendPostbackKycForm customerId={customer.id} className={classes.kycReset}/>
              </Descriptor>
            </div>
            <Typography variant="body2" color="primary">SMS</Typography>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Per day" top={7}>
                <Input name="smsesPerDay" label="Per day" />
              </Descriptor>
            </div>
            <Typography variant="body2" color="primary">Currencies</Typography>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Included currencies" className={classes.descriptorWrapper} top={7}>
                <CurrencySelect name="includedCurrencies" label="Included currencies" multi fullWidth margin="normal" optionDisabled/>
              </Descriptor>
              <Descriptor group="customerSettings" title="Excluded currencies" className={classes.descriptorWrapper} top={7}>
                <CurrencySelect name="excludedCurrencies" label="Excluded currencies" multi fullWidth margin="normal" optionDisabled/>
              </Descriptor>
            </div>
            <Typography variant="body2" color="primary">APM settings</Typography>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Disabled Legal Entity Limits for APMs" className={classes.descriptorWrapper} top={7}>
                {/*<ApmSelect */}
                {/*  name="disabledLegalEntityLimitsApm" */}
                {/*  label="Disabled Legal Entity Limits for Apms" multi fullWidth margin="normal" optionDisabled/>*/}
                <ApmSelect
                  name="disabledLegalEntityLimitsApm"
                  multi
                  label="Disabled Legal Entity Limits for APMs"
                  margin={"normal"}
                />
              </Descriptor>
            </div>
            <Typography variant="body2" color="primary">Payment settings</Typography>
            <div className={formClasses.row}>
              <Descriptor group="paymentSettings" title="Included groups" top={7}>
                <Select name="paymentSettings.includedGroups" label="Included groups" multi fullWidth options={groups} margin="normal"/>
              </Descriptor>
              <Descriptor group="paymentSettings" title="Excluded groups" top={7}>
                <Select name="paymentSettings.excludedGroups" label="Excluded groups" multi fullWidth options={groups} margin="normal"/>
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Descriptor group="paymentSettings" title="Included methods" top={7}>
                <Select name="paymentSettings.includedMethods" label="Included methods" multi fullWidth options={methods} margin="normal"/>
              </Descriptor>
              <Descriptor group="paymentSettings" title="Excluded methods" top={7}>
                <Select name="paymentSettings.excludedMethods" label="Excluded methods" multi fullWidth options={methods} margin="normal"/>
              </Descriptor>
            </div>
            <div className={formClasses.row}>
              <Descriptor group="paymentSettings" title="Included providers" top={7}>
                <Select name="paymentSettings.includedProviders" label="Included providers" multi fullWidth options={providers} margin="normal"/>
              </Descriptor>
              <Descriptor group="paymentSettings" title="Excluded providers" top={7}>
                <Select name="paymentSettings.excludedProviders" label="Excluded providers" multi fullWidth options={providers} margin="normal"/>
              </Descriptor>
            </div>

            <Typography variant="body2" color="primary">Orders</Typography>
            <div className={formClasses.row}>
              <Descriptor group="customerSettings" title="Excluded orders" top={7}>
                <Select
                  name="excludedOrders"
                  label="Excluded orders"
                  multi
                  fullWidth
                  margin="normal"
                  options={Object.keys(OrderType).map((t) => ({ value: t, label: t }))}
                />
              </Descriptor>
            </div>
            { customer.authorities?.includes('ROLE_MERCHANT') && <>
              <Typography variant="body2" color="primary">Settlement currency</Typography>
              <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Settlement currency" top={8}>
                  <CurrencySelect
                      name="settlementCryptoCurrency"
                      label="Currency"
                      type={CurrencyType.CRYPTO}
                      margin="normal"
                      fullWidth
                  />
                </Descriptor>
                <div />
              </div>
              <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Manual blockchain tx" top={7}>
                  <Checkbox name="manualBlockchainTx" label="Manual blockchain tx" />
                </Descriptor>
                <Descriptor group="customerSettings" title="Enable Legal entity limits" top={7}>
                  <Checkbox name="enableLegalEntityLimits" label="Enable Legal entity limits" />
                </Descriptor>
              </div>
              <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Show masked card no in callback" top={7}>
                  <Checkbox name="maskedCardNumCallback" label="Show masked card no in callback" />
                </Descriptor>
                <Descriptor group="customerSettings" title="Enable additional order details for Coinspaid" top={7}>
                  <Checkbox name="coinspaidAdditionalDetails" label="Enable additional order details for Coinspaid" />
                </Descriptor>
              </div>
              <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Authorise customer with token" top={7}>
                  <Checkbox name="tokenAuthorizationEnabled" label="Authorise customer with token" />
                </Descriptor>
                <Descriptor group="customerSettings" title="Disable extra AML validation" top={7}>
                  <Checkbox name="disableExtraAMLValidation" label="Disable extra AML validation" />
                </Descriptor>
              </div>
              <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Ignore country Occasional tx" top={7}>
                  <Checkbox name="ignoreCountryOccasionalTx" label="Ignore country Occasional tx" />
                </Descriptor>
              </div>
              <div className={formClasses.row}>
                <Input name="dmrProxyUrl" label="DMR proxy URL" validate={isURL} />
                <Descriptor group="customerSettings" title="Use DMR proxy" top={7} className={classes.checkbox}>
                  <Checkbox name="dmrProxy" label="Use DMR proxy" />
                </Descriptor>
              </div>
              <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Ultra-low min deposit" top={7}>
                  <Input name="ultraLowMinDeposit"
                         label="Ultra-low min deposit (EUR)"
                         validate={composeValidators(required, mustBeNumber, (v: any) => minValue(v, 0))}/>
                </Descriptor>
                {/* WW-1620 Hide FE 1441 logic | OBI
                <Descriptor group="customerSettings" title="Instant payment amount" top={7}>
                    <Input name="instantPaymentAmount"
                           label="Instant OBI payment amount (EUR)"
                           validate={composeValidators(mustBeNumber, (v: any) => minValue(v, 0))}/>
                </Descriptor> */}
                <Descriptor group="customerSettings" title="OBI Transaction Type Preferences" top={7}>
                  <Select 
                    name="obiPaymentScheme"
                    label="OBI Transaction Type Preferences"
                    noEmptyOption
                    fullWidth
                    options={transactionTypePreferences} 
                    margin="normal" 
                  />
                </Descriptor>
              </div>
              <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Fail order on address change" top={7}>
                    <Checkbox name="failOrderWhenWalletAddressChanged" label="Fail order on address change" />
                </Descriptor>
                <Descriptor group="customerSettings" title="Simplified flow (no CoR, KYC, etc.)" top={7}>
                  <Checkbox name="simplifyUserFlow" label="Simplified flow (no CoR, KYC, etc.)" />
                </Descriptor>
              </div>
              <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Closed-circuit wallets" top={7}>
                  <Checkbox name="ccw.enabled" label="Enable closed-circuit wallets" />
                </Descriptor>
                <Descriptor group="customerSettings" title="KYC flow" top={7}>
                    <Select
                        name="kycFlow"
                        label="KYC flow"
                        options={Object.keys(KycFlowType).map((t) => ({ value: t, label: t }))}
                        validate={required}
                        fullWidth
                        margin="none"
                        noEmptyOption
                    />
                </Descriptor>
              </div>
              { values.ccw?.enabled && <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Closed-circuit wallets factory" top={7}>
                  <Input
                      name="ccw.factory"
                      label="Factory address"
                      validate={composeValidators(required, isEvmAddress)}
                  />
                </Descriptor>
                <Descriptor group="customerSettings" title="Closed-circuit wallets vault" top={7}>
                  <Input
                      name="ccw.vault"
                      label="Vault address"
                      validate={composeValidators(required, isEvmAddress)}
                  />
                </Descriptor>
              </div> }
              <div className={formClasses.row}>
                <Descriptor group="customerSettings" title="Send KYC meta info in postback" top={7}>
                  <Checkbox name="sendKycMeta" label="Send KYC meta info in postback" />
                </Descriptor>
                <Descriptor group="customerSettings" title="Promotion" top={7}>
                  <Checkbox name="promotion" label="Promotion" />
                </Descriptor>
              </div>
            </> }
            <CustomerBusinessForm customerSettings={customer?.settings} />
            <div className={formClasses.actions}>
              <Button
                onClick={handleSubmit}
                disabled={invalid}
                size="large"
                variant="contained"
              >
                Save
              </Button>
            </div>
          </div>
        )
      }}
    />
  );
}
