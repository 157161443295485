import React, { useEffect, useState } from 'react';

import { Form } from 'react-final-form';

import clsx from 'clsx';

import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import DetailsPopup from '../../../components/Layout/DetailsPopup';
import { Input } from '../../../components/Form';
import { composeValidators, mustBeNumber, required } from '../../../validators';
import { ISettlement, useSettlementCreate, useSettlementOne, useSettlementUpdate } from '../../../api/settlements';
import useFormStyles from '../../../hooks/useFormStyles';
import { useCurrenciesList } from '../../../api/currency';
import { useCustomerDetails } from '../../../api/customers';
import SettlementDialog from './SettlementDialog';
import SettlementInfo from './SettlementInfo';
import SettlementCreateForm from './SettlementCreateForm';

const useStyles = makeStyles((theme) => ({
  form: {
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px'
    },
  },
  title: {
    marginBottom: theme.spacing(2.5),
  },
}));

interface ISettlementDetails {
  id: string;
  onClose: ()=> void;
  updateList: (id: string)=> void
}

export default function SettlementDetails({ id, onClose, updateList }: ISettlementDetails) {
  const classes = useStyles();
  const formClasses = useFormStyles();

  const [openDialog, setOpenDialog] = useState(false);

  const [{ items, error }, loading, load] = useSettlementOne();
  const [{ items: currencies }, loadingCurrencies, loadCurrencies] = useCurrenciesList();
  const [{ data: customer, error: errorCustomer }, loadingCustomer, loadCustomer] = useCustomerDetails();

  const [create, creating] = useSettlementCreate((res: any) => {
    if (res.success) {
      updateList(id);
      onClose();
    }
  });

  const [update, updating] = useSettlementUpdate((res: any) => {
    if (res.success) {
      updateList(id);
      load(id);
    }
    setOpenDialog(false);
  });

  const item: ISettlement = items[0];

  useEffect(()=>{
    loadCurrencies();
  },[]);

  useEffect(() => {
    if (id !=='new') {
      load(id);
    }
  }, [id]);

  if (error) {
    return <Typography align="center">No data to display</Typography>;
  }

  return (
    <DetailsPopup onClose={onClose} loading={loading || updating || creating || loadingCurrencies}>
      {item?.id && <SettlementInfo setOpenDialog={setOpenDialog} settlement={item} />}

      {id === 'new' && (
        <>
          <Typography variant="h6" className={classes.title} color="primary">Create settlement</Typography>
          {!customer?.id && 
            <Form
              onSubmit={(values)=> loadCustomer(values.id)}
              initialValues={{
                id: '',
              }}
              render={({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className={clsx(formClasses.root, classes.form)}>
                  <div className={formClasses.row}>
                    <Input
                      name="id"
                      label="Merchant ID"
                      validate={composeValidators(required, mustBeNumber )}
                    />
                  </div>
                  <div className={formClasses.actions}>
                    <Button
                      type="submit"
                      variant="contained"
                    >
                      Apply
                    </Button>
                  </div>
                </form>
              )}
            />
          }

          {customer?.id && <SettlementCreateForm currencies={currencies} customer={customer} create={create} />}
        </>
      )}
      <>
        {openDialog && <SettlementDialog currencies={currencies} setOpenDialog={setOpenDialog} update={update} settlement={item} />}
      </>
    </DetailsPopup>
  );
}
