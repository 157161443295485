import React, { useEffect, useState } from 'react';

import makeStyles from '@material-ui/core/styles/makeStyles';

import { Typography } from '@material-ui/core';
import Loader from '../../../components/Loader';
import EmptyList from '../../../components/EmptyList';
import { IMasterAccountBalance, useCurrencyBalances, useMasterAccountBalances } from '../../../api/walletOperations';
import CurrencyBalancesList from './Wallets/CurrencyBalancesList';
import SearchSubstr from '../../../components/SearchSubstr/SearchSubstr';
import MasterAccountBalancesList from './Wallets/MasterAccountBalancesList';

const useStyles = makeStyles((theme) => ({
  root: {},
  filter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '15px',
    paddingTop: '10px',
    marginBottom: '20px',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  block: {
    marginBottom: '20px',
  }
}));

interface IWalletsMasterAccountTab {
  id: number
}

export default function WalletsMasterAccountTab({ id }: IWalletsMasterAccountTab) {
  const [{ items: masterAccountBalances, error: masterAccountBalancesError }, masterAccountBalancesLoading, masterAccountBalancesLoad] = 
    useMasterAccountBalances();
  const [{ items: currencyBalances, error: currencyBalancesError }, currencyBalancesLoading, currencyBalancesload] = 
    useCurrencyBalances();

  const [searchByCurrency, setSearchByCurrency] = useState('');
  const [searchByEmail, setSearchByEmail] = useState('');

  const classes = useStyles();

  const searchFilterByCurrency = masterAccountBalances.filter((item: IMasterAccountBalance) =>
    item.currency.toLowerCase().includes(searchByCurrency.toLowerCase()));

  const searchFilterByEmail = searchFilterByCurrency.filter((item: IMasterAccountBalance) =>
    item.email.toLowerCase().includes(searchByEmail.toLowerCase()));

  useEffect(() => {
    if (id) {
      currencyBalancesload(id);
      masterAccountBalancesLoad(id);
    }
  }, [id]);

  return (
    <Loader loading={currencyBalancesLoading || masterAccountBalancesLoading} width={450}>
      <Typography variant="body1" color="primary" component="div" className={classes.filter}>
        <Typography variant="body1" component="div">
          <SearchSubstr search={searchByCurrency} setSearch={setSearchByCurrency} label="Currency" id="searchByCurrency" labelWidth={60} />
        </Typography>
        <Typography variant="body1" component="div">
          <SearchSubstr search={searchByEmail} setSearch={setSearchByEmail} label="Email" id="searchByEmail" />
        </Typography>
      </Typography>
      <Typography variant="body1" component="div" className={classes.block}>
        <Typography variant="body1" color="primary">Currency Balances</Typography>
        {currencyBalances.length > 0
            ? <CurrencyBalancesList currencyBalances={currencyBalances} />
            : <EmptyList error={currencyBalancesError} loading={currencyBalancesLoading} />
        }
      </Typography>
      <Typography variant="body1" color="primary">Master account balances</Typography>
      {searchFilterByEmail.length > 0
          ? <MasterAccountBalancesList balances={searchFilterByEmail} />
          : <EmptyList error={masterAccountBalancesError} loading={masterAccountBalancesLoading} />
      }  
    </Loader>
  );
}