import React, { useEffect } from 'react';

import { Button, Typography, makeStyles } from '@material-ui/core';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';

import { DataRow } from '../../../components/DataView';
import Label from '../../../components/Label';
import DateView from '../../../components/DateView';
import { getSettlementStatusType } from '../../../helpers/settlementHelper';
import { ISettlement, SettlementStatus } from '../../../api/settlements';
import PopupLink from '../../../components/UI/PopupLink';
import { useCustomerDetails } from '../../../api/customers';

interface IMappedSettlementOperation {
  id: string
  amount: number
  currency: string
}

interface ISettlementInfo {
  settlement: ISettlement
  setOpenDialog: (open: boolean)=> void
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2.5),
  },
}));

function SettlementInfo({ settlement, setOpenDialog }:ISettlementInfo) {

  const classes = useStyles();

  const [{ data: customer }, loadingCustomer, loadCustomer] = useCustomerDetails();

  const mappedSettlementOperations: IMappedSettlementOperation[] = [];
  let customerId = '';

  if (Object.keys(settlement.operations).length > 0) {
    customerId = Object.keys(settlement.operations)[0];
    Object.entries(settlement.operations).forEach(([id, requestInfo]) =>{
       Object.entries(requestInfo as Record<string, number>).forEach(([currency, amount]) => {
        mappedSettlementOperations.push({ id, currency, amount });
      });
    });
  }

  useEffect(() => {
    if (customerId) {
      loadCustomer(customerId);
    }
  }, [customerId]);

  return(
    <>
      <Typography 
        color="primary"
        variant="h6"
        className={classes.title}
      >
        Settlement ID {settlement.id}
      </Typography>
      <DataRow label="Status"><Label label={settlement.status} type={getSettlementStatusType(settlement.status)} size="small" /></DataRow>
      <DataRow label="Merchant ID">
        <PopupLink popup="customer" id={settlement.customerId} baseUrl={'/customers'}>
          {settlement.customerId}
        </PopupLink>
      </DataRow>
      <DataRow label="Legal entity">
        {customer?.legalEntity ?? ''}
      </DataRow>
      <DataRow label="Requested amount">{settlement.requestedEurAmount} EUR</DataRow>
      <DataRow label="Settled amount">{settlement?.settlementAmount ? `${settlement.settlementAmount} ${settlement.settlementCurrency}` : '-'}</DataRow>
      <DataRow label="Created"><DateView value={settlement.createdAt} /></DataRow>
      <DataRow label="Updated"><DateView value={settlement.updatedAt} /></DataRow>
      { settlement.status === SettlementStatus.REQUESTED && (
        <DataRow label="Сhange status">
          <Button
            variant="contained"
            size="small"
            onClick={() => setOpenDialog(true)}
          >
            Сhange
          </Button>
        </DataRow>
      )}

      <Typography variant="body1" component="div" color="primary">Requested amounts</Typography>
      {mappedSettlementOperations.length > 0 && 
        <>
          <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Customer ID</TableCell>
              <TableCell align="right">Amount</TableCell>
              <TableCell>Currency</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mappedSettlementOperations.map((row, index) =>
              (
                <TableRow key={index}>
                  <TableCell>
                    <PopupLink popup="customer" id={row.id} baseUrl={'/customers'}>
                      {row.id}
                    </PopupLink>
                  </TableCell>
                  <TableCell align="right">{row.amount}</TableCell>
                  <TableCell>{row.currency}</TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
        </>}
    </>
  );
}
export default SettlementInfo;