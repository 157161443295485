import React, { useEffect, useState } from 'react';

import { Waypoint } from 'react-waypoint';

import makeStyles from '@material-ui/core/styles/makeStyles';
import { 
  FormControl, 
  InputLabel, 
  MenuItem, Select, 
  FormControlLabel, 
  TableRow, 
  TableBody, 
  TableCell, 
  TableHead, 
  Table, 
  Typography,
  Checkbox,
  Button
} from '@material-ui/core';

import Loader from '../../../components/Loader';
import EmptyList from '../../../components/EmptyList';
import DateView from '../../../components/DateView';
import FinanceForm from './Wallets/FinanceForm';
import { useCustomerWallets, ICustomerWallet } from '../../../api/customers';
import { useWalletOperations } from '../../../api/walletOperations';
import { ICurrency, useCurrenciesList } from '../../../api/currency';
import { useBreakpoints } from '../../../hooks/useBreakpoints ';
import useMobileStyles from '../../../hooks/useMobileStyles';
import Tablecell from '../../../components/TableCell/TableCell';
import OperationsList from './Wallets/OperationsList';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '10px',
    marginBottom: '10px',
    [theme.breakpoints.down(700)]: {
      flexDirection: 'column',
      gap: '15px',
      alignItems: 'flex-start',
    },
  },
  filters: {
    [theme.breakpoints.down(700)]: {
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      width: '100%',
    },
  },
  table: {
    marginBottom: theme.spacing(2)
  },
  checkbox: {
    fontSize: 10
  },
  select: {
    width: '200px',
    marginRight: '20px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  }
}));

interface ICustomerWallets {
  id: number
}
export default function CustomerWallets({ id }: ICustomerWallets) {
  const [{ items: wallets, error: walletsError }, loading, load] = useCustomerWallets();
  const [{ items: operations, error: operationsError, full }, operationsLoading, loadOperations] = useWalletOperations();
  const [{ items: currencies }, loadingCurrencies, loadCurrencies] = useCurrenciesList();
  const classes = useStyles();
  const [allWallets, setAllWallets] = useState(true);
  const [operation, setOperation] = useState();
  const [hideEmptyBalances, setHideEmptyBalances]= useState(true);
  const [currencyType, setCurrencyType] = useState('FIAT');
  const isResolutionSevenHundred = useBreakpoints(700);
  const mobileClasses = useMobileStyles();

  useEffect(() => {
    if (id) {
      load(id);
      loadCurrencies()
    }
  }, [id])

  const filteredList = wallets.filter((b: ICustomerWallet) => {
    const curr = currencies.find(
      (c: ICurrency) =>
        c.currency === b.currency &&
        (currencyType === 'All' || c.type === currencyType)
    )
    return !!curr && (!hideEmptyBalances || b.balance > 0)
  })

  const sortItems = ((a: ICustomerWallet, b: ICustomerWallet) => {
    const nameA = a.currency.toUpperCase(); 
    const nameB = b.currency.toUpperCase(); 
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });

  const showWallets = allWallets ? [...filteredList].sort(sortItems) : [...filteredList].filter((w: ICustomerWallet) => !!w.id);

  const handleChangeType = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCurrencyType(event.target.value as string)
  }

  return (
    <Loader loading={loading || loadingCurrencies} width={450}>
      {operation && (
        <FinanceForm
          customerId={id}
          type={operation.type}
          currency={operation.currency}
          onSuccess={() => {
            setOperation(undefined);
            load(id);
          }}
          cancel={() => setOperation(undefined)}
          max={operation.type === 'deposit' ? undefined : operation.max}
        />
      )}
      <Typography variant="body1" color="primary" component="div" className={classes.title}>
        <span>Wallets</span>
        <div className={classes.filters}>
        <FormControl variant="standard" className={classes.select}>
          <InputLabel id="balance-label" variant="outlined">
           Balance
          </InputLabel>
          <Select
            labelId="balance-label"
            id="balance-select"
            variant="outlined"
            value={currencyType}
            onChange={handleChangeType}
            label="Balance"
            fullWidth={isResolutionSevenHundred}
          >
            <MenuItem value={'All'}>All</MenuItem>
            <MenuItem value={'CRYPTO'}>Crypto</MenuItem>
            <MenuItem value={'FIAT'}>Fiat</MenuItem>
          </Select>
        </FormControl>
        <FormControlLabel
          control={<Checkbox checked={hideEmptyBalances} onChange={() => setHideEmptyBalances(!hideEmptyBalances)} />}
          label="Hide empty balances"
        />
        <FormControlLabel
          control={<Checkbox checked={allWallets} onChange={() => setAllWallets(!allWallets)} />}
          label="Show all"
        />
        </div>
      </Typography>
      {walletsError
        ? <EmptyList loading={loading} error={walletsError} />
        : (
          <Table size="small" className={classes.table}>
            <TableHead className={mobileClasses.tableHead}>
              <TableRow>
                <TableCell align="center">ID</TableCell>
                <TableCell>Currency</TableCell>
                <TableCell>Balance</TableCell>
                <TableCell>Locked</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Updated</TableCell>
                {/*<TableCell />*/}
              </TableRow>
            </TableHead>
            <TableBody>
              {showWallets.map((w: ICustomerWallet) => (
                <TableRow key={w.currency} className={mobileClasses.row}>
                  <Tablecell title="ID">{w.id}</Tablecell>
                  <Tablecell title="Currency">{w.currency}</Tablecell>
                  <Tablecell title="Balance">{w.balance}</Tablecell>
                  <Tablecell title="Locked">{w.locked}</Tablecell>
                  <Tablecell title="Created">
                    <DateView value={w.createdAt} />
                  </Tablecell>
                  <Tablecell title="Updated">
                    <DateView value={w.updatedAt} />
                  </Tablecell>
                  <TableCell align="center" className={mobileClasses.blockMargin}>
                    <Button
                      size="small"
                      variant="text"
                      color="primary"
                      onClick={() => setOperation({
                        type: 'deposit',
                        currency: w.currency
                      })}
                    >
                      Deposit
                    </Button>
                    {w.balance > 0 && (
                      <Button
                        size="small"
                        variant="text"
                        color="secondary"
                        onClick={() => setOperation({
                          type: 'withdrawal',
                          currency: w.currency,
                          max: w.balance
                        })}
                      >
                        Withdrawal
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
     {operations.length > 0 && <Typography variant="body1" color="primary">Operations</Typography>}
     {operationsError 
        ? <EmptyList loading={operationsLoading} error={operationsError} width={500} />
        : (<>
            <OperationsList operations={operations} />
            <Loader loading={operationsLoading} />
            {!operationsLoading && !operationsError && !full
            && <Waypoint onEnter={() => loadOperations({ cursor: operations.length, filter: { customerId: id } })} />}
          </>
        )}
    </Loader>
  );
}
